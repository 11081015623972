<template>
  <div>
    <v-tooltip top color="primary">
      <template #activator="{ on }">
        <v-btn icon v-on="on" @click="newEntry()">
          <v-icon color="primary">
            fal fa-plus-circle
          </v-icon>
        </v-btn>
      </template>
      <span>Add Funding Source</span>
    </v-tooltip>

    <!--Entry-->
    <v-dialog v-model="show" scrollable persistent max-width="700">
      <v-card flat class="white">
        <v-card-title class="text-h5 secondary--text"
          >Funding Source Details<v-spacer /><v-btn text rounded outlined @click="cancelEntry()"
            ><v-icon small left>fal fa-times</v-icon>Cancel</v-btn
          ></v-card-title
        >
        <v-card-text>
          <v-form ref="entryForm" v-model="valid" lazy-validation>
            <v-container fluid>
              <v-row dense>
                <!--Name-->
                <v-col cols="12" sm="12">
                  <v-text-field
                    v-model="d_name"
                    label="Name"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Funding Code-->
                <v-col cols="12" sm="4">
                  <v-text-field
                    v-model="d_code"
                    label="Base Funding Code"
                    placeholder=" "
                    persistent-placeholder
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Use For Teacher Payment-->
                <v-col cols="12" sm="12" md="4">
                  <v-autocomplete
                    :items="valueListsStore.yesNo"
                    label="Use For Teacher Payments"
                    placeholder=" "
                    persistent-placeholder
                    item-text="value"
                    item-value="id"
                    v-model="f_teacherReview"
                  />
                </v-col>
              </v-row>

              <v-row dense>
                <!--Status-->
                <v-col cols="12" sm="12" md="4">
                  <v-autocomplete
                    :items="valueListsStore.activeStatus"
                    label="Status"
                    placeholder=" "
                    persistent-placeholder
                    item-text="value"
                    item-value="id"
                    v-model="f_status"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn block rounded color="primary" @click="saveEntry()"
            ><v-icon small left>fal fa-check</v-icon>Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { createHelpers } from "vuex-map-fields";

const { mapFields } = createHelpers({
  getterType: "fundingSourcesStore/getEntryField",
  mutationType: "fundingSourcesStore/updateEntryField"
});

export default {
  name: "FundingSourceEntry",
  computed: {
    ...mapState({
      fundingSourcesStore: state => state.fundingSourcesStore,
      valueListsStore: state => state.valueListsStore
    }),
    ...mapFields(["show", "valid", "mode", "id", "d_name", "d_code", "f_teacherReview", "f_status"])
  },

  methods: {
    async newEntry() {
      this.valid = true;
      this.mode = 1;
      this.id = "";
      this.d_name = "";
      this.f_teacherReview = 0;
      (this.d_code = ""), (this.f_status = 1);
      this.show = true;
    },

    cancelEntry() {
      this.show = false;
    },

    async saveEntry() {
      if (this.$refs.entryForm.validate()) {
        const entry = this.$store.getters["fundingSourcesStore/getEntry"];
        /* Create */
        if (this.mode === 1) {
          await this.$store.dispatch("fundingSourcesStore/create", entry);
        } else {
          /* Edit */
          await this.$store.dispatch("fundingSourcesStore/update", entry);
        }
        this.cancelEntry();
      }
    }
  }
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
button {
  outline: none;
}
</style>
